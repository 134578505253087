<template>
  <div id="news">
    <b-container class="containerNo-pad">
      <b-row>
        <b-col
          lg="12"
          md="6"
          v-for="(postCard, index) in filterStory.slice(0, 4)"
          :key="index"
        >
            <template v-if="develop == 'adt'">
              <b-link :to="'/adt/blog/' + postCard.slug" class="newsLinkadt">
                <img class="newsImage" :src="postCard.better_featured_image.source_url">
              </b-link>
            </template>
            <template v-else>
              <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                <img class="newsImage" :src="postCard.better_featured_image.source_url">
              </b-link>
            </template>
            <div class="newsTitle">News</div>
            <template v-if="develop == 'adt'">
              <b-link :to="'/adt/blog/' + postCard.slug" class="newsLinkadt">
                <div class="newsHeading" :class="{'adtcolor' : develop == 'adt'}" v-html="postCard.title.rendered"></div>
              </b-link>
            </template>
            <template v-else>
              <b-link :to="'/blog/' + postCard.slug" class="newsLink">
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
            </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sideBar",
  props: ["develop", "config"],
  data() {
    return {
      posts: [],
    };
  },
  computed: {
    setDev: function() {
      if (this.develop === "apga")
        return process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/posts?_embed";
      else return process.env.VUE_APP_WP_ADT_API_URL + "wp/v2/posts?_embed";
    },
    filterStory: function() {
      return this.posts.filter((posts) => posts.slug !== this.$route.params.id);
    },
  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(this.setDev) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.posts = response.data));},1000)
  },
};
</script>

<style scoped>
div#news {
  /* margin-top: 68px; */
  margin-top: 12.7em;
}
::v-deep a.newsLink > div:hover {
  color: #5897E6!important;
  cursor: pointer;
}
::v-deep a.newsLink{
  cursor: pointer;
  text-decoration: none!important;
}
::v-deep .adtcolor {
  color: #76c3bd!important;
}
::v-deep a.newsLinkadt > div:hover{
  color: #1c857c!important;
  text-decoration: none!important;
}
::v-deep a.newsLinkadt{
  text-decoration: none!important;
}
.newsImage {
  width: 100%;
  max-height: 200px;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}
.newsContent {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}
.containerNo-pad {
  padding: 0;
}
.wholePage {
  padding-bottom: 20%;
}
.more {
  text-align: center;
}
.show {
  padding-bottom: 2%;
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 35px;
  margin-right: 15px;
}
.card-img-top {
  width: 100%;
  height: auto;
  /* min-height: 183px; */
  border-radius: 10px 10px 0 0 !important;
  /* max-height: 180px; */
}
.card-body {
  padding: 15px;
  /* min-height: 197px; */
  background: #fff;
}
.card-footer {
  border-radius: 0 0 10px 10px !important;
  background-color: #133f7b;
  color: white;
  text-align: center;
  padding: 0;
  margin-bottom: 1em;
}
::v-deep a.nav-link:hover {
  color: #fff;
  background-color: #7aafef;
  border-radius: 0 0 10px 10px !important;
}
#title {
  padding-top: 5%;
  padding-bottom: 5%;
}
.card {
  background-color: transparent;
  border: 0;
}
.exre {
  font-size: 14px;
  color: #b4b4b4;
}
.pt {
  color: #133f7a;
  font-size: 16px;
}
::v-deep .nav-link {
  color: white !important;
  background-color: #133f7b;
  margin-top: -12%;
  font-size: 1.4vh !important;
  border-radius: 0px 0px 10px 10px;
}

.space {
  padding-bottom: 2%;
  border-bottom: 1px solid lightgrey;
  padding-top: 2%;
}
.developGreen > .card-footer {
  background-color: #76c3bd !important;
}
.developGreen > .card-footer > li > .nav-link {
  background-color: #76c3bd !important;
}
@media only screen and (max-width: 500px) {
  .newsImage {
    width: 100%;
    max-height: 100%;
  }
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    margin-top: -5%;
    text-align: center;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
  .card-img-top{
    width: 100%;
    height: auto;
    /* min-height: 183px; */
    border-radius: 10px 10px 0 0 !important;
    max-height: max-content;
  }
}
</style>
